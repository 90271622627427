import React from "react";
import { StrictMode } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";
import AOS from "aos";
import "aos/dist/aos.css";
import "antd/dist/antd.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect, useLayoutEffect } from "react";
import Home from "./components/Home/Home";
import Content from "./components/Content/Content";
import Contact from "./components/Contact/Contact";
import ContactUs from "./components/ContactUs/ContactUs";
import MandatoryDisclosures from "./components/MandatoryDisclosures/MandatoryDisclosures";
import Booklist from "./components/Content/Booklist";
import Studentslogin from "./components/Content/Studentslogin";
import studentcover from "./image/primary_cover.jpg";
import CampusTour from "./components/Content/CampusTour";
import Examination from "./components/Content/Examination";
import Faculty from "./components/Content/Faculty";
import MessageCard from "./components/Cards/messageCard";
import Pedagogi from "./components/Content/Pedagogi";
import { FaSchool } from "react-icons/fa";
import alumniTopIcon from "./image/alumniTopIcon.svg";
import pedagogiIcon from "./image/pedagogyTopIcon.svg";
import infroTopIcon from "./image/infraTopIcon.svg";
import msgTopIcon from "./image/newImg/message.png";
import currTopIcon from "./image/newImg/curr.png";
import assesTopIcon from "./image/newImg/asses.png";
import skillTopIcon from "./image/newImg/svt.png";
import stuCouncilTopIcon from "./image/newImg/council.png";
import tripTopIcon from "./image/newImg/trip.png";
import scoutTopIcon from "./image/newImg/scout.png";
import infraTopIcon from "./image/newImg/infra.png";
import achTopIcon from "./image/newImg/ach.png";
import evTopIcon from "./image/newImg/ev.png";
import nwTopIcon from "./image/newImg/nw.png";
import adTopIcon from "./image/newImg/ad.png";
import sportsTopIcon from "./image/newImg/sicon.png";
import Achievements from "./components/Content/Achievement";
import Blogs from "./components/Blogs/blogs";
import BlogPostPagee from "./components/Blogs/BlogPostPage";
import {
  about_data,
  academics_sidenav,
  admission,
  admissions_data,
  alumni_data,
  alumni_text_data,
  awards_data,
  award_text_data,
  beyond_sidenav,
  career_guidance,
  co_curricular_data,
  curriculum,
  events_data,
  examination_data,
  facilities_data,
  faculty_data,
  house_clubs_data,
  infrastructure,
  life_curriculum_data,
  message_data,
  ncc_scouts_guides_data,
  nep_data,
  news_data,
  news_text_data,
  pre_primary_data,
  primary_data,
  results_data,
  secondary_data,
  sidebar_data_vision,
  skill_vocational_data,
  sports_data,
  sr_secondary_data,
  student_council_data,
  transportation_data,
  trip_data,
  vision_mission,
} from "./variable";
//cover
import campus_tour from "./image/newcovers/Cover image for about us.jpg";
import vision_mission_cover2 from "./image/newcovers/Cover image for about us.jpg";
import vision_mission_cover from "./image/newImg/Assembly (1).jpeg";
import message_cover from "./image/message_cover.jpg";
import facilities_cover from "./image/facilities_cover.jpg";
import transportation_cover from "./image/transportation_cover.jpg";
import curriculum_cover from "./image/curriculum/Curriculum Cover pic.jpg";
import infra_cover from "./image/infra_cover.jpg";
import career_cover from "./image/career_cover.jpg";
import alumni_cover from "./image/newImg/Alumni Cover Page.jpeg";
import pre_primary_cover from "./image/newcovers/Cover pic pre primary curriculum.jpg";
import secondary_cover from "./image/secondary/Sec.Currciulum Cover pic.jpg";
import sr_secondary_cover from "./image/newImg/sr sec.jpeg";
import nep_cover from "./image/newcovers/Cover Image for NEP ready schools.jpg";
import life_skill_cover from "./image/newcovers/Life Skill Curriculum Cover Pic.jpg";
import examination_cover from "./image/newcovers/assessment cover pic.jpg";
import co_curricular_cover from "./image/co_curricular_cover.jpg";
import clubs_cover from "./image/newImg/Houses (2).jpg";
import student_council_cover from "./image/student-council/Student council cover pic.jpg";
import trip_2 from "./image/trip_2.jpg";
import ncc_cover from "./image/newcovers/Scout and Guide Cover pic.jpg";
import booklist_cover from "./image/booklist_cover.jpg";
import skill_vocational_cover from "./image/skill-vocational/Skill and Vocational Cover pic.jpg";
import sports_cover from "./image/newcovers/Sports and Fitness cover pic.jpg";
import events_cover from "./image/events_cover.JPG";
import results_cover from "./image/newcovers/Results coevr pic.jpg";
import booklist from "./image/booklist.jpg";
import awards_cover from "./image/awards_cover.jpg";
import addmission_cover from "./image/admission_cover2.jpg";
import faculty_cover from "./image/faculty_cover2.jpg";
import SportsCard from "./components/Content/SportsCard";
import news_cover from "./image/news.jpg";
import parents_cover from "./image/newImg/Parents (1).jpeg";
import pedagogi_cover from "./image/newcovers/Pedagogical Approach Cover pic.jpg";
import Parents from "./components/Content/parentsVoice";
import ParentLogin from "./components/Content/Parentslogin";
import Vision from "./components/Content/vision";
import Lifeskill from "./components/Content/Lifeskill";
import Results from "./components/Content/Results";
import Registration from "./components/Registration/Registration";
import StudentTCTable from "./components/Table/StudentTCTable";

import ReactGA from "react-ga";
import { NewRegistration } from "./components/NewRegistration/NewRegistration";
import { ExistingFees } from "./components/ExistingFees/ExistingFees";
import Transaction from "./components/transaction/Transaction";
import Privacy from "./components/privacy/Privacy";
import Sitemap from "./components/sitemap/Sitemap";
import NewAdmission from "./components/Admission/Admission";
import Books from "./components/Content/Books";
import Feespayment from "./components/Content/feespayment";
import { Helmet } from "react-helmet";
const TRACKING_ID = "G-53PXRKEXLD";
ReactGA.initialize(TRACKING_ID);

function App() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    AOS.init({
      duration: 500,
      once: false,
      mirror: true,
      anchorPlacement: "bottom-bottom",
    });
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  useEffect(() => {
    if (AOS) {
      AOS.refresh();
    }
  });

  return (
    <StrictMode>
      <BrowserRouter>
        <Helmet>
          <title>
            Hermann Gmeiner School | The Best CBSE School in Faridabad
          </title>
          {/* <meta
            name="title"
            description="We proudly stand by our teaching methods because we deliver consistent 100% results that reflect our dedication to maintaining high academic standards."
          /> */}          
          <meta name="Keywords" content="Hermann gmeiner school faridabad, NEP 2020, 10 board cbse result, 10 class cbse board result, best schools in near me, cbse schools near me, good schools near me, high schools near me, primary school, primary schools, primary schools near me, private school, schools close by me, schools near me, schools nearby" />
          <meta name="description" content="Welcome to Hermann Gmeiner School Faridabad, a premier CBSE school dedicated to excellence in education. Join a vibrant community focused on futuristic education and personal growth." />
          <link rel="canonical" href="https://hermanngmeinerschoolfaridabad.org/" />
        </Helmet>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route
            path="/pedagogi"
            element={
              <Pedagogi
                cover={pedagogi_cover}
                sidebar_data={academics_sidenav}
                side_heading="Academics & Curriculum"
                headingColor={"#1FDC00D1"}
                topicon={pedagogiIcon}
              />
            }
          />
          <Route
            path="/vision-and-mission"
            element={
              <Vision
                cover={vision_mission_cover2}
                sidebar_data={sidebar_data_vision}
              />
            }
          />
          <Route
            path="/message"
            element={
              <Content
                cover={message_cover}
                sidebar_data={sidebar_data_vision}
                data={message_data}
                topicon={msgTopIcon}
                title={"Message From Leadership"}
                headingColor={"#0076BD"}
                canonicalUrl = {`https://hermanngmeinerschoolfaridabad.org/message`}
              />
            }
          />
          <Route
            path="/news"
            element={
              <Content
                cover={news_cover}
                sidebar_data={sidebar_data_vision}
                data={news_text_data}
                news_data={news_data}
                topicon={nwTopIcon}
                title={"News & Media Coverage"}
                headingColor={"#0076BD"}
              />
            }
          />
          <Route
            path="/campus-tour"
            element={
              <CampusTour
                cover={infra_cover}
                sidebar_data={sidebar_data_vision}
                data={vision_mission}
              />
            }
          />
          <Route
            path="/facilities"
            element={
              <Content
                cover={facilities_cover}
                sidebar_data={sidebar_data_vision}
                data={facilities_data}
              />
            }
          />
          <Route
            path="/transportation"
            element={
              <Content
                cover={transportation_cover}
                sidebar_data={sidebar_data_vision}
                data={transportation_data}
                title="Transportation"
              />
            }
          />
          <Route
            path="/curriculum"
            element={
              <Content
                cover={curriculum_cover}
                sidebar_data={academics_sidenav}
                data={null}
                title="Curriculum"
                curriculum_data={curriculum}
                side_sub_heading={true}
                topicon={currTopIcon}
                headingColor={"#F91984ED"}
                side_heading="Academics & Curriculum"
              />
            }
          />
          <Route
            path="/sports"
            element={
              <SportsCard
                cover={sports_cover}
                title="Sports & Fitness"
                sidebar_data={sidebar_data_vision}
                data={sports_data}
              />
            }
          />
          <Route
            path="/parents"
            element={
              <Parents
                cover={parents_cover}
                sidebar_data={sidebar_data_vision}
              />
            }
          />
          <Route
            path="/parent-login"
            element={
              <ParentLogin
                cover={parents_cover}
                sidebar_data={sidebar_data_vision}
              />
            }
          />
          <Route
            path="/student-login"
            element={
              <Studentslogin
                cover={studentcover}
                sidebar_data={sidebar_data_vision}
              />
            }
          />
          <Route path="/new-registration" element={<NewRegistration />} />
          <Route path="/transaction" element={<Transaction />} />
          <Route path="/existing-fees" element={<ExistingFees />} />
          <Route
            path="/infrastructure"
            element={
              <Content
                cover={infra_cover}
                title="Infrastructure & Facilities"
                sidebar_data={sidebar_data_vision}
                data={infrastructure}
                headingColor={"#F91984ED"}
                topicon={infroTopIcon}
                link={"infrastructure"}
                canonicalUrl = {"https://hermanngmeinerschoolfaridabad.org/infrastructure"}
              />
            }
          />

          <Route
            path="/career-guidance"
            element={
              <Content
                cover={career_cover}
                sidebar_data={sidebar_data_vision}
                data={career_guidance}
              />
            }
          />
          <Route
            path="/awards"
            element={
              <Achievements
                cover={awards_cover}
                sidebar_data={sidebar_data_vision}
              />
            }
          />
          <Route
            path="/results"
            element={
              <Results
                cover={results_cover}
                sidebar_data={academics_sidenav}
                side_sub_heading={true}
                side_heading="Academics & Curriculum"
              />
            }
          />
          <Route
            path="/books"
            element={
              <Books
                cover={booklist}
                sidebar_data={academics_sidenav}
                side_sub_heading={true}
                side_heading="Academics & Curriculum"
              />
            }
          />
          <Route
            path="/alumni"
            element={
              <Content
                cover={alumni_cover}
                sidebar_data={sidebar_data_vision}
                data={null}
                alumni_data={alumni_data}
                title="Illustrious Alumni"
              />
            }
          />
          <Route path="admissions" element={<NewAdmission />} />
          <Route
            path="/pre-primary"
            element={
              <Content
                cover={pre_primary_cover}
                sidebar_data={sidebar_data_vision}
                data={pre_primary_data}
                headingColor={"#F91984ED"}
              />
            }
          />
          <Route
            path="/primary"
            element={
              <Content
                title="Primary Curriculum"
                cover={pre_primary_cover}
                sidebar_data={sidebar_data_vision}
                data={primary_data}
                headingColor={"#F91984ED"}
                link="primary"
              />
            }
          />
          <Route
            path="/secondary"
            element={
              <Content
                title="Secondary Curriculum"
                cover={secondary_cover}
                sidebar_data={sidebar_data_vision}
                data={secondary_data}
                headingColor={"#F91984ED"}
              />
            }
          />
          <Route
            path="/sr-secondary"
            element={
              <Content
                title="Senior Secondary Curriculum"
                cover={sr_secondary_cover}
                sidebar_data={sidebar_data_vision}
                data={sr_secondary_data}
                headingColor={"#F91984ED"}
                canonicalUrl = {`https://hermanngmeinerschoolfaridabad.org/sr-secondary`}
              />
            }
          />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route
            path="/mandatory-disclosures"
            element={<MandatoryDisclosures />}
          />
          <Route path="/student-registration" element={<Registration />} />
          <Route
            path="/about-us"
            element={
              <Content
                cover={campus_tour}
                sidebar_data={sidebar_data_vision}
                data={about_data}
              />
            }
          />
          
          <Route
            path="/nep"
            element={
              <Content
                cover={nep_cover}
                flaticon={<FaSchool style={{ fontSize: 50 }} />}
                title="NEP 2020 Ready School"
                icon={<FaSchool />}
                sidebar_data={academics_sidenav}
                data={nep_data}
                side_sub_heading={true}
                side_heading="Academics & Curriculum"
                link="nep"
                canonicalUrl = {`https://hermanngmeinerschoolfaridabad.org/nep`}
              />
            }
          />
          
          <Route
            path="/life-skill"
            element={
              <Lifeskill
                cover={life_skill_cover}
                sidebar_data={academics_sidenav}
                side_sub_heading={true}
                side_heading="Academics & Curriculum"
              />
            }
          />
          <Route
            path="/examination"
            element={
              <Examination
                cover={examination_cover}
                sidebar_data={academics_sidenav}
                data={examination_data}
                topicon={assesTopIcon}
                side_heading="Academics  & Curriculum"
                headingColor={"#0440B6ED"}
              />
            }
          />

          <Route
            path="/skill-vocational"
            element={
              <Content
                cover={skill_vocational_cover}
                title="Skill & Vocational Subjects"
                data={skill_vocational_data}
                sidebar_data={academics_sidenav}
                side_sub_heading={true}
                topicon={skillTopIcon}
                side_heading="Academics & Curriculum"
                headingColor={"#F91984ED"}
              />
            }
          />

          <Route
            path="/co-curricular"
            element={
              <Content
                cover={co_curricular_cover}
                title="Co-Curricular Activities"
                sidebar_data={beyond_sidenav}
                data={co_curricular_data}
                side_sub_heading={true}
                side_heading="Beyond Curriculum"
                headingColor={"#FF8C22ED"}
              />
            }
          />

          <Route
            path="/house-clubs"
            element={
              <Content
                cover={clubs_cover}
                title="Houses & Clubs"
                sidebar_data={beyond_sidenav}
                data={house_clubs_data}
                side_sub_heading={true}
                side_heading="Beyond Curriculum"
                link="clubs"
                showBubble={true}
              />
            }
          />

          <Route
            path="/student-council"
            element={
              <Content
                cover={student_council_cover}
                sidebar_data={beyond_sidenav}
                title="Student Council"
                data={student_council_data}
                side_sub_heading={true}
                link="student-council"
                side_heading="Beyond Curriculum"
                headingColor={"#0076BD"}
                flaticon={stuCouncilTopIcon}
              />
            }
          />

          <Route
            path="/trips"
            element={
              <Content
                cover={trip_2}
                title="Trips, Excursions & Field Visits"
                sidebar_data={beyond_sidenav}
                data={trip_data}
                topicon={tripTopIcon}
                side_sub_heading={true}
                side_heading="Beyond Curriculum"
                headingColor={"#1FDC00"}
                link="trips"
                canonicalUrl = {`https://hermanngmeinerschoolfaridabad.org/trips`}
                flaticon={tripTopIcon}
              />
            }
          />
          <Route
            path="/ncc-scouts"
            element={
              <Content
                cover={ncc_cover}
                title="NCC, Scouts & Guides"
                sidebar_data={beyond_sidenav}
                data={ncc_scouts_guides_data}
                side_sub_heading={true}
                topicon={scoutTopIcon}
                side_heading="Beyond Curriculum"
                headingColor="#FF8C22"
                link="ncc-scouts"
                flaticon={scoutTopIcon}
                canonicalUrl = {`https://hermanngmeinerschoolfaridabad.org/ncc-scouts`}
              />
            }
          />
          <Route
            path="/booklist-uniform"
            element={
              <Booklist
                cover={booklist_cover}
                sidebar_data={academics_sidenav}
                side_sub_heading={true}
                side_heading="Academics & Curriculum"
              />
            }
          />

          <Route
            path="/inspired"
            element={
              <Content
                cover={alumni_cover}
                title={"See What Our Alumni Say"}
                sidebar_data={sidebar_data_vision}
                data={alumni_text_data}
                showBubble={true}
                link="inspired"
                headingColor={"#F91984BF"}
                topicon={alumniTopIcon}
                canonicalUrl = {`https://hermanngmeinerschoolfaridabad.org/inspired`}
              />
            }
          />
          <Route
            path="/faculty-staff"
            element={
              <Faculty
                cover={faculty_cover}
                sidebar_data={sidebar_data_vision}
                data={faculty_data}
              />
            }
          />

          <Route
            path="/events"
            element={
              <Content
              canonicalUrl = {`https://hermanngmeinerschoolfaridabad.org/events`}
                cover={events_cover}
                title="Events & Happenings"
                sidebar_data={sidebar_data_vision}
                topicon={evTopIcon}
                data={events_data}
                headingColor={"#FF8C22ED"}
                link="events"
                flaticon={evTopIcon}
              />
            }
          />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/post/:id" element={<BlogPostPagee />} />
          <Route path="/student-tc" element={<StudentTCTable />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="sitemap" element={<Sitemap />} />

          <Route
            path="/Fees-Payment"
            element={
              <Feespayment
                cover={awards_cover}
                sidebar_data={sidebar_data_vision}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </StrictMode>
  );
}

export default App;
