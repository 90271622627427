import { Button, Table, Typography } from "antd";
import axios from "axios";
import React, { useState } from "react";
import Footer from "../Footer/Footer";
import Navbar from "../NavBar/Navbar";
import NavbarMenu from "../NavbarMenu/NavbarMenu";
import { Input } from "../NewRegistration/Input";
import { FORM } from "./constants";
import NewNavbar from "../NavBar/NewNavbar";
import PaymentButton from "../Content/Paymentbutton";
const keys = [
  "FB_MONTH",
  "FB_TOTAL",
  "FB_YEAR",
  "HGSF_MONTHLY_FEE_BILL_ID",
  "HGSF_STD_MFBILL_ID",
  "breakup",
];

export const ExistingFees = () => {


  const [amount, setAmount] = useState(''); // Default amount in paisa (50000 paisa = 500 INR)
 
  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const handlePayment = async () => {
    // Correct the amount calculation (e.g., remove the extra 100 if not needed)
    const calculatedAmount = feesData.TOTAL_BALANCE * 100;
    setAmount(calculatedAmount); 
  
    const isScriptLoaded = await loadRazorpayScript();
  
    if (!isScriptLoaded) {
      alert('Failed to load Razorpay SDK. Are you online?');
      return;
    }
  
    // Use the updated amount for Razorpay
    const options = {
      key: 'rzp_live_eut8QP40VZNJKk',  // Replace with your Razorpay Key ID
      amount: calculatedAmount,  // Set the amount correctly
      currency: 'INR',
      name: 'Your Company',
      description: 'Test Transaction',
      handler: function (response) {
        // Handle successful payment
        alert(`Payment ID: ${response.razorpay_payment_id}`);
        alert(`Order ID: ${response.razorpay_order_id}`);
        alert(`Signature: ${response.razorpay_signature}`);
      },
      prefill: {
        name: '',
        email: '',
        contact: '',
      },
      theme: {
        color: '#3399cc',
      },
    };
  
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };
  

  let minOffset = 0,
    maxOffset = 30;
  let thisYear = new Date().getFullYear();
  let allYears = [];
  for (let x = 0; x <= maxOffset; x++) {
    allYears.push(thisYear - x);
  }

  const yearList = allYears.map((x) => {
    return <option key={x}>{x}</option>;
  });
  const [isScrolled, setIsScrolled] = useState(false);
  const [isShow, steIsShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(false);
  const [feesData, setFeesData] = useState([]);
  const handleScroll = (e) => {
    if (e.target.scrollTop > 0 && !isScrolled) {
      setIsScrolled(true);
    }
    if (e.target.scrollTop === 0) {
      setIsScrolled(false);
    }
  };
  const handleClick = () => {
    steIsShow(!isShow);
    console.log(isShow);
  };
  const [form, setForm] = useState({});
  const onChange = (e) => {
    const { value, name } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  };
  const onItemClick = ({ fieldName, value }) => {
    setForm((prev) => ({ ...prev, [fieldName]: value }));
  };
// val bro=false;
  const submitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      //https://eschool.appsosindia.org/views/api/payment_details_new.php?reg_code=HGSJ/1112/193
      const result = await axios.get(
        // `https://eschool.appsosindia.org/views/api/payment_details_new.php?reg_code=HGSJ/1112/193`
        `https://hgs.kyloapps.com/paymentfees?reg_code=${form.reg_no}`
        //  `https://eschool.appsosindia.org/views/api/payment_details_new.php?reg_code=${form.reg_no}`
        //  `https://eschool.appsosindia.org/views/accounts/php/fee_enquiry.php?reg_no=${form.reg_no}&mobile=${form.mobile}&academic_year=${form.academic_year}&school_code=HGSF`
      );
      // console.log(result.data.payment_info);
      setLoading(false);
      setResult(result.data.payment_info?.length > 0 ? false : true);
      // console.log(result);
      setFeesData(result.data.payment_info);
      console.log(feesData,"feesdata");
      console.log(feesData.length);
      // const datatbe=Array.from(feesData);
      // console(datatbe,"datate");
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  function isDate(val) {
    // Cross realm comptatible
    return Object.prototype.toString.call(val) === "[object Date]";
  }
  function isObj(val) {
    return typeof val === "object";
  }
  function stringifyValue(val) {
    if (isObj(val) && !isDate(val)) {
      return JSON.stringify(val);
    } else {
      return val;
    }
  }

  const buildFormForCC = (data) => {
    const form = document.createElement("form");
    form.setAttribute("id", "nonseamless");
    form.setAttribute("name", "redirect");
    form.setAttribute("method", "post");
    form.setAttribute("action", data.url);

    const input = document.createElement("input");
    input.setAttribute("id", "encRequest");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", "encRequest");
    input.setAttribute("value", stringifyValue(data["encRequest"]));
    form.appendChild(input);

    const input2 = document.createElement("input");
    input2.setAttribute("id", "access_code");
    input2.setAttribute("type", "hidden");
    input2.setAttribute("name", "access_code");
    input2.setAttribute("value", stringifyValue(data["accessCode"]));
    form.appendChild(input2);
    return form;
  };

  function postCC(details) {
    const form = buildFormForCC(details);
    // console.log(form);
    document.body.appendChild(form);
    form.submit();
    form.remove();
  }

  const payment = async (amount, fees_id) => {
    const response = await axios.post(
      "https://kylo-ccavenue.onrender.com/api/ccavenue/create/order",
      {
        studentPhone: form.mobile,
        totalAmount: amount,
        schoolCode: "HGSF",
        reg_id: form.reg_no,  
        fees_id: 1234,
        academic_year: form.academic_year,
      }
    );
    postCC(response.data);
  };
  const buttonStyle = {
    backgroundColor: '#3399cc',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    fontSize: '16px',
    cursor: 'pointer',
  };

  const columns = [
    {
      title: "Month",
      dataIndex: "FB_MONTH",
      key: "FB_MONTH",
    },
    {
      title: "Breakup",
      dataIndex: "breakup",
      key: "breakup",
      render: (text, record) =>
        text.map((item) => (
          <div>
            <Typography.Link>
              {item.FEE_NAME} : {item.FB_AMOUNT}
            </Typography.Link>
          </div>
        )),
    },
    {
      title: "Total",
      dataIndex: "FB_TOTAL",
      key: "FB_TOTAL",
    },
    {
      title: "Year",
      dataIndex: "FB_YEAR",
      key: "FB_YEAR",
    },
    {
      title: "Monthly Id",
      dataIndex: "HGSF_MONTHLY_FEE_BILL_ID",
      key: "HGSF_MONTHLY_FEE_BILL_ID",
    },

    {
      title: "Student Bill Id",
      dataIndex: "HGSF_STD_MFBILL_ID",
      key: "HGSF_STD_MFBILL_ID",
    },
    {
      title: "Payment ",
      key: "Payment",
      dataIndex: "",
      render: (_, record) => (
        <button
          className="apply-btn cursor"
          onClick={() => payment(record.FB_TOTAL, record.HGSF_STD_MFBILL_ID)}
        >
          Payment
        </button>
      ),
    },
  ];


  return (
    <div>
      <div className="home_main" onScroll={handleScroll}>
        <NewNavbar handleClick={handleClick} />
        {/* <Navbar isScrolled={isScrolled} handleClick={handleClick} /> */}
        <NavbarMenu isShow={isShow} handleClick={handleClick} />
        <div className="pt-5 mt-5 flex-center d-flex justify-content-center align-items-center">
          <div>
            <form
              onSubmit={submitForm}
              className="d-flex my-5 justify-content-center align-items-center flex-column"
            >
              <div className="row d-flex justify-content-center">
                {FORM["STEP_1"].map((item) =>
                  item.name !== "academic_year" ? (
                    <Input
                      value={item.value}
                      id={item.name}
                      readOnly={item.readOnly}
                      key={item.name}
                      {...item}
                      form={form}
                      onChange={onChange}
                      onClick={onItemClick}
                    />
                  ) : (
                    <>
                      <select
                        className="input-container mt-3 mx-1"
                        value={item.value}
                        id={item.name}
                        name={item.name}
                        key={item.name}
                        {...item}
                        onChange={onChange}
                        onClick={onItemClick}
                      >
                        {/* Placeholder text */}
                        <option value="">Select Academic Year</option>

                        {/* Render the yearList options */}
                        {yearList}
                      </select>
                    </>
                  )
                )}
              </div>

              <div className="flex-center">
                <button
                  type="submit"
                  disabled={loading}
                  className="apply-btn cursor my-3 "
                >
                  {loading ? "Loading..." : "Submit"}
                </button>
              </div>
            </form>
            {!result && (
              <div className="d-flex justify-content-center align-items-center flex-column">               
                <h5> {!result && "No Result Found, Try another !"}</h5>
              </div>
            )}
          </div>
        </div>
        {result && (
          <div className="pt-5  mx-5">
            <div>
              <p>TOTAL BILL : {feesData.TOTAL_BILL}</p>
              <p>TOTAL RECEIPT : {feesData.TOTAL_RECEIPT}</p>
              <p>TOTAL BALANCE : {feesData.TOTAL_BALANCE}</p>
            </div>
            <button
          className="apply-btn cursor"
          onClick={handlePayment} style={buttonStyle}
        >
          Make Fees Payment
        </button>
        {/* <button class = "payment-btn" onClick={handlePayment} style={buttonStyle}>Pay Now</button> */}

            {/* <Table
              columns={columns}
              // dataSource={feesData}
              loading={loading}
              rowKey="HGSF_MONTHLY_FEE_BILL_ID"
            /> */}
          </div>
        )}
        <Footer />
      </div>
    </div>
  );
};